<template>
  <div>
    <ul class="steps mb-0 p-0">
      <li v-for="(day, i) in days" :key="day.day" class="step-item">
        <div class="indicator-wrap col-4">
          <div @click="confirmDeleteCampaignDay(day)" class="wheel">
            <svg-icon name="trash" class="trash-icon" />
          </div>
        </div>
        <div class="col-96">
          <div class="head-row">
            <div class="day mr-2">Day {{ i + 1 }}</div>
            <span class="no-reply">{{ day.formatedDate }}</span>
          </div>

          <div class="step-posts">
            <div v-for="post in day.posts" :key="post.id" @click="editPost(post)" class="step-post">
              <div class="status-icon col-4">
                <svg-icon v-if="post.status === 'published'" name="check-circle-success" />
                <svg-icon v-else name="check-circle" />
              </div>
              <div class="col post-time">{{ post.utcScheduleTime }}</div>
              <div class="s-accounts text-center">
                <span
                  class="sa-photo"
                  v-for="publishAccount in formatPublishAccounts(post.publishAccounts)"
                  :key="publishAccount.id"
                >
                  <img
                    v-if="!publishAccount.indicator"
                    :src="publishAccount.imageUrl"
                    width="40"
                    alt="Acount photo"
                    class="rounded-circle"
                  />
                  <span v-else-if="publishAccount.indicator" class="more-account-photos">{{
                    publishAccount.more
                  }}</span>
                </span>
              </div>
              <div class="body text-truncate col">
                <img
                  v-if="getAccountPostImage(post)"
                  :src="getAccountPostImage(post)"
                  width="20"
                  height="20"
                  class="rounded-sm mr-2"
                />
                <span>{{ getAccountPostContent(post) }}</span>
              </div>
              <div class="actions col-9">
                <div class="email-actions">
                  <span class="mr-2"><svg-icon name="edit"/></span>
                  <span class="mr-2" @click.stop="confirmDeleteCampaignPost(post)"><svg-icon name="trash"/></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <b-modal id="campaignPostDeleteModal" ref="campaignPostDeleteModal" centered hide-footer hide-header>
      <div v-if="isDeletingCampaignPost" class="text-center py-5">
        <spinner size="4" />
      </div>
      <div v-else class="text-center py-5">
        <h4>Delete Post?</h4>
        <p>Are you sure you would like to delete this post?</p>

        <b-button variant="clear" class="px-4 mr-2" @click="cancelDeleteCampaignPost">Cancel</b-button>
        <b-button class="px-4" :disabled="isDeletingCampaignPost" @click="actuallyDeleteCampaignPost">Delete</b-button>
      </div>
    </b-modal>
    <b-modal id="campaignDayDeleteModal" ref="campaignDayDeleteModal" hide-footer hide-header>
      <div v-if="isDeletingCampaignDay" class="text-center py-5">
        <spinner size="4" />
      </div>
      <div v-else class="text-center py-5">
        <template v-if="campaignDayToDelete">
          <h4>Delete {{ campaignDayToDelete.posts.length }} Posts?</h4>
          <p>
            Are you sure you would like to delete all {{ campaignDayToDelete.posts.length }} posts scheduled for
            <strong>{{ campaignDayToDelete.formatedDate }}</strong
            >?
          </p>

          <b-button variant="clear" class="px-4 mr-2" @click="cancelDeleteCampaignDay">Cancel</b-button>
          <b-button class="px-4" :disabled="isDeletingCampaignDay" @click="actuallyDeleteCampaignDay">Delete</b-button>
        </template>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { stripTags } from '~/utils/helpers';
import { DELETE_CAMPAIGN_POSTS_MUTATION } from '~/graphql/mutations';

export default {
  name: 'campaign-posts',

  props: {
    days: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      campaignPostToDelete: null,
      isDeletingCampaignPost: false,
      isDeleteCampaignPostCanceled: false,
      campaignDayToDelete: null,
      isDeletingCampaignDay: false,
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),
  },

  mounted() {
    this.$root.$on('bv::modal::hidden', (bvModalEvt, modalId) => {
      if (
        (modalId == 'campaignPostDeleteModal' || modalId == 'campaignDayDeleteModal') &&
        !this.isDeleteCampaignPostCanceled
      ) {
        this.$emit('delete');
      }
    });
  },

  methods: {
    formatPublishAccounts(publishAccounts) {
      if (publishAccounts.length > 4) {
        // Cut out 3 and add more indicator
        const slicedOut = publishAccounts.slice(0, 3);
        return [
          ...slicedOut,
          {
            id: 'more',
            indicator: true,
            more: `+${publishAccounts.length - slicedOut.length}`,
          },
        ];
      }

      return publishAccounts;
    },

    getAccountPostContent(post) {
      const first = post.accountPostContents[0];
      return stripTags(first ? first.content : post.masterContent);
    },

    getAccountPostImage(post) {
      const first = post.accountPostContents[0];
      const firstAttachment = first ? first.attachments[0] : false;
      return firstAttachment ? firstAttachment.url : '';
    },

    editPost(post) {
      this.$emit('edit', post);
    },

    cancelDeleteCampaignPost() {
      this.isDeleteCampaignPostCanceled = true;

      this.closeConfirmDeleteCampaignPost();
    },

    confirmDeleteCampaignPost(post) {
      this.campaignPostToDelete = post;

      this.$refs.campaignPostDeleteModal.show();
    },

    closeConfirmDeleteCampaignPost() {
      this.campaignPostToDelete = null;

      this.$refs.campaignPostDeleteModal.hide();
    },

    async actuallyDeleteCampaignPost() {
      this.isDeletingCampaignPost = true;
      this.isDeleteCampaignPostCanceled = false;

      await this.$apollo.mutate({
        mutation: DELETE_CAMPAIGN_POSTS_MUTATION,
        variables: {
          workspace: this.workspace.id,
          ids: [this.campaignPostToDelete.id],
        },
      });

      this.isDeletingCampaignPost = false;
      this.closeConfirmDeleteCampaignPost();
    },

    cancelDeleteCampaignDay() {
      this.isDeleteCampaignPostCanceled = true;

      this.closeConfirmDeleteCampaignDay();
    },

    confirmDeleteCampaignDay(post) {
      this.campaignDayToDelete = post;

      this.$refs.campaignDayDeleteModal.show();
    },

    closeConfirmDeleteCampaignDay() {
      this.campaignDayToDelete = null;

      this.$refs.campaignDayDeleteModal.hide();
    },

    async actuallyDeleteCampaignDay() {
      this.isDeletingCampaignDay = true;
      this.isDeleteCampaignPostCanceled = false;

      const ids = this.campaignDayToDelete.posts.map(p => p.id);
      await this.$apollo.mutate({
        mutation: DELETE_CAMPAIGN_POSTS_MUTATION,
        variables: {
          workspace: this.workspace.id,
          ids,
        },
      });

      this.isDeletingCampaignDay = false;
      this.closeConfirmDeleteCampaignDay();
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.day {
  padding: 0 0 20px;
  font-weight: 700;
  color: #4a556b;
}
.no-reply {
  color: #9ba1ad;
}
.step-item {
  padding-bottom: 40px;
  border-left: 1px solid #ccced2;
  display: flex;

  &:last-child {
    border-left: 0;
  }

  .col {
    flex: 1;
  }
  .col-4 {
    flex: 1 1 4%;
    max-width: 4%;
    max-height: 100%;
  }
  .col-9 {
    flex: 1 1 9%;
    max-width: 9%;
    max-height: 100%;
  }
  .col-96 {
    flex: 1 1 96%;
    max-width: 96%;
    max-height: 100%;
  }

  .head-row {
    display: flex;
  }

  .wheel {
    outline: none;
    cursor: pointer;
    position: absolute;
    left: -8px;
    background-color: #fff;
    width: 16px;
    height: 16px;
    border: 1px solid #9ba1ad;
    color: #dfe0e3;
    text-align: center;
    line-height: 14px;
    border-radius: 50%;
    background-clip: padding-box;

    .trash-icon {
      display: block;
      width: 25px;
      height: 25px;
      padding: 4px 0;
      position: relative;
      right: 6px;
      bottom: 4px;
      z-index: 28;
      color: #000;
      background: #fff;
      transition: opacity 0.15s;
      filter: alpha(opacity=0);
      opacity: 0;
    }
  }

  .add-btn {
    position: absolute;
    left: -5px;
    bottom: -20px;
    background-color: #fefefe;
    font-size: 20px;
    color: #111;
    filter: alpha(opacity=0);
    opacity: 0;
    transition: all 0.12s;
  }
  &:hover {
    .add-btn,
    .trash-icon {
      opacity: 1;
    }
  }
}
.date-dropdown {
  cursor: pointer;
}
.follow-up-title {
  font-size: 15px;
  color: #4a556b;
  line-height: 2.6;
}

.follow-up-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;

  .control-material {
    margin-bottom: 10px;

    input {
      padding: 6px 5px 1px;
    }
  }

  .follow-up-label {
    margin-right: 15px;
    font-size: 14px;
    color: #4a556b;
    line-height: 1.2;
  }
}

.step-posts {
  border-radius: 4px;
  background-clip: padding-box;
  box-shadow: 0 1px 4px #ddd;
  transition: box-shadow 0.2s;
  padding: 6px 0;

  &:hover {
    box-shadow: 0 1px 4px #bdbdbd;
  }

  .step-post {
    position: relative;
    align-items: center;
    margin: 0 15px;
    padding: 20px 0;
    color: #7383a4;
    border-top: 1px solid #dfe0e3;
    outline: none;
    cursor: pointer;
    display: flex;

    &:first-child {
      border-top-color: #fff;
    }

    .status-icon {
      margin-right: 10px;

      .icon {
        height: 1.3rem;
        width: 1.3rem;
      }
    }

    .s-accounts {
      color: #111;
      font-weight: 600;
      font-size: 16px;
      max-width: 150px;
      white-space: nowrap;
      display: flex;
      align-items: center;

      .sa-photo {
        display: inline-block;
        height: 40px;
        width: 40px;
        margin-left: -10px;
        background-color: white;
        border-radius: 50%;

        &:first-of-type {
          margin-left: 0;
        }

        img {
          border: 1px solid $gray-200;
          padding: 2px;
          box-shadow: 0px 0px 10px rgba($gray-300, 0.54);
        }

        .more-account-photos {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          width: 40px;
          background-color: $gray-500;
          color: $white;
          border-radius: 50%;
          border: 2px solid $white;
        }
      }
    }
    .post-time {
      max-width: 110px;
    }
    .body {
      color: #4a556b;
      font-size: 15px;
    }

    .email-actions {
      opacity: 0;
    }

    &:hover {
      .email-actions {
        opacity: 1;
      }
    }
  }
}
</style>
