<template>
  <transition name="fade" mode="out-in"> <router-view /> </transition>
</template>

<script>
import app from '~/main';
import { FETCH_CAMPAIGN_QUERY } from '~/graphql/queries';

export default {
  metaInfo() {
    return { title: this.title };
  },

  data() {
    return {
      title: '',
    };
  },

  async beforeRouteEnter(to, from, next) {
    const workspace = app.$store.state.workspace.current;
    const campaign = await app.$apollo
      .query({
        query: FETCH_CAMPAIGN_QUERY,
        variables: {
          workspace: workspace.id,
          id: to.params.id,
        },
      })
      .then(({ data }) => {
        if (data.campaign) {
          app.$store.dispatch('campaign/updateViewing', data.campaign);
          return data.campaign;
        }
      });

    next(vm => (vm.title = campaign.title));
  },

  beforeDestroy() {
    this.$store.dispatch('createPost/reset');
  },
};
</script>
