<template>
  <b-navbar :variant="variant" class="campaign-navbar p-0">
    <b-container class="flex-wrap">
      <div class="w-100 d-flex justify-content-between align-items-center pl-4 pr-5">
        <div class="d-flex align-items-center">
          <slot name="back-button" />
          <campaign-title
            :bgVariant="variant"
            :title="campaign.title"
            :campaignId="campaign.id"
            @update="updateCampaignTitle"
          />
        </div>

        <slot name="right" />
      </div>

      <slot />
    </b-container>
  </b-navbar>
</template>

<script>
import { mapState } from 'vuex';
import CampaignTitle from '~/components/CampaignTitle';

export default {
  name: 'campaign-navbar',

  components: { CampaignTitle },

  props: {
    variant: {
      type: String,
      default: 'light',
    },
  },

  computed: {
    ...mapState('campaign', {
      campaign: state => state.viewing,
    }),
  },

  methods: {
    updateCampaignTitle(title) {
      this.$store.dispatch('campaign/updateViewing', {
        ...this.campaign,
        title,
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.campaign-navbar {
  position: relative;
  box-shadow: 0 0.125rem 0.15rem rgba($gray-600, 0.07);
}
.navbar.fixed-top.campaign-navbar {
  top: $navbar-height !important;
  z-index: 29;
}
</style>
