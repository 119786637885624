<template>
  <ViewCampaignMaster>
    <b-button slot="back-button" :to="{ name: 'campaigns' }" class="mr-3 py-1" variant="light">
      <svg-icon name="arrow-left" class="mt-0" />
    </b-button>
    
    <template slot="fab">
      <div class="campaign-fabs">
        <button
          id="addBtn"
          @click="isButtonsShowing = !isButtonsShowing"
          class="fab-button"
          :class="{ pulse: !isButtonsShowing }"
        >
          <svg-icon name="plus" class="button-icon" :class="{ rotate: isButtonsShowing }" />
          <b-tooltip v-if="!isButtonsShowing" triggers="hover" container="addBtn" target="addBtn" placement="left"
            >Add posts to this Campaign</b-tooltip
          >
        </button>
        <transition name="slideout">
          <div v-show="isButtonsShowing" class="popout-buttons">
            <router-link id="socialBtn" to="" @click.native="handleCompose('social')" class="fab-button social">
              <svg-icon name="social" class="button-icon" />
              <b-tooltip triggers="hover" container="socialBtn" target="socialBtn" placement="top"
                >Compose Social Media post</b-tooltip
              >
            </router-link>
            <router-link id="editBtn" to="" @click.native="handleCompose('blog')" class="fab-button edit">
              <svg-icon name="edit" class="button-icon" />
              <b-tooltip triggers="hover" container="editBtn" target="editBtn" placement="top"
                >Compose Blog post</b-tooltip
              >
            </router-link>
          </div>
        </transition>
      </div>
    </template>

    <section class="pt-5">
      <div class="container">
        <div v-if="$apollo.queries.campaignPosts.loading" class="text-center py-5"><spinner size="4" /></div>
        <campaign-posts
          v-if="days && days.length > 0 && !$apollo.queries.campaignPosts.loading"
          :days="days"
          @edit="handleCompose($event.type, $event.id)"
          @delete="handleCampaignPostDelete"
        />
        <div
          v-if="days && days.length < 1 && !$apollo.queries.campaignPosts.loading"
          class="no-campaign-posts text-center p-5"
        >
          <img class="pt-3" width="250" src="@/assets/images/campfire.svg" alt="" />
          <h5 class="mt-4">There are no Posts in this campaign yet.</h5>
          <p>
            Click the <svg-icon name="plus" class="text-primary mx-2" /> button at the top right corner to add posts.
          </p>
          <!-- <p>Here: {{ composerType }}</p>
          <p>Here: {{ composerPostId }}</p> -->
        </div>
      </div>
    </section>

    <PostComposer
      v-if="showComposer"
      fixed
      :type="composerType"
      :id="composerPostId"
      @saved="postSaved"
      @close="handleCloseComposer"
    />
  </ViewCampaignMaster>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import ViewCampaignMaster from './master';
import PostComposer from '~/components/PostComposer';
import CampaignPosts from '~/components/CampaignPosts';
import { CAMPAIGN_POSTS_QUERY } from '~/graphql/queries';

export default {
  components: { PostComposer, ViewCampaignMaster, CampaignPosts },

  data() {
    return {
      isButtonsShowing: false,
      campaignPosts: {},
      showComposer: false,
      offset: 0,
    };
  },
  type() {
      return this.$route.params.type;
    },
  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),
    composerType() {
      return this.$route.query.compose || '';
    },
    composerPostId() {
      return parseInt(this.$route.params.id, 10) || 0;
    },
    days() {
      return this.campaignPosts ? this.campaignPosts.days : [];
    },
  },

  apollo: {
    campaignPosts: {
      query: CAMPAIGN_POSTS_QUERY,
      variables() {
        return {
          workspace: parseInt(this.workspace.id, 10),
          campaignId: parseInt(this.$route.params.id, 10),
          offset: this.offset,
        };
      },
    },
  },

  mounted() {
    this.$store.dispatch('createPost/updatePostStatus', 'scheduled');
    this.$store.dispatch('createPost/updateParentType', 'campaign');

    if (this.$route.query.compose) {
      this.showComposer = true;
    }

    if (this.$apollo.queries.campaignPosts) {
      this.$apollo.queries.campaignPosts.refetch();
    }
  },

  methods: {
    handleCompose(type, postId = 0) {
      this.$store.dispatch('createPost/updateCampaignId', parseInt(this.$route.params.id, 10));

      // <router-link :to="{ name: 'compose', params: { type: 'social', id: 0 } }">
      // this.$router.push({ name: 'compose', params: { id: saved.id } });

      this.$router.push({ query: { compose: type, id: postId } }, () => (this.showComposer = true));
    },

    handleCloseComposer() {
      this.showComposer = false;

      this.$router.push({ query: {} }).catch(() => {});
    },

    handleCampaignPostDelete() {
      if (this.$apollo.queries.campaignPosts) {
        this.$apollo.queries.campaignPosts.refetch();
      }
    },

    readQueryData() {
      const store = this.$apollo.getClient();
      // Read the data from our cache for this query.
      return store.readQuery({
        query: CAMPAIGN_POSTS_QUERY,
        variables: {
          workspace: this.workspace.id,
          campaignId: parseInt(this.$route.params.id, 10),
          offset: this.offset,
        },
      });
    },

    writeQueryData(data) {
      const store = this.$apollo.getClient();
      store.writeQuery({
        query: CAMPAIGN_POSTS_QUERY,
        variables: {
          workspace: this.workspace.id,
          campaignId: parseInt(this.$route.params.id, 10),
          offset: this.offset,
        },
        data,
      });
    },

    postSaved(saved) {
      if (saved) {
        this.$notify({
          group: 'main',
          type: 'native',
          title: 'Success!',
          text: 'Your post has been saved successfully.',
        });

        this.handleCloseComposer();

        const data = this.readQueryData();

        const date = moment(saved.createDate);
        const day = date.format('D');
        const formatedDate = date.format('MMM D YYYY');

        const index = data.campaignPosts.days.findIndex(d => d.day === day);
        if (index !== -1) {
          data.campaignPosts.days.splice(index, 1, {
            ...data.campaignPosts.days[index],
            posts: [...data.campaignPosts.days[index].posts, saved],
          });
        } else {
          data.campaignPosts.days.push({
            day,
            formatedDate,
            posts: [saved],
          });
        }
        try {
          this.writeQueryData(data);
        } catch (e) {
          // Errors
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';

.no-campaign-posts {
  h5 {
    line-height: 1.7;
  }
}
.campaign-fabs {
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  bottom: -27px;
  right: 60px;

  .fab-button {
    background: lighten($danger, 5);
    margin-right: 10px;
    margin-bottom: 0;
    height: 60px;
    width: 60px;

    .button-icon {
      fill: #fff;
      transition: all 0.2s ease-in-out;

      &.rotate {
        transform: rotate(45deg);
      }
    }

    &.social {
      background: lighten($success, 5);
    }

    &.edit {
      background: lighten($info, 5);
    }
  }

  .popout-buttons {
    margin-right: 15px;
    display: flex;
    align-items: center;
  }
}

.slideout-enter {
  width: 0px;
}

.slideout-enter-active,
.slideout-leave-active {
  transition: visibility 0s linear 0s, opacity 0.5s, width 1s linear 0s;
}

.slideout-enter,
.slideout-leave-to {
  opacity: 0;
}
</style>