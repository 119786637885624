<template>
  <div class="title-wrap">
    <input
      v-model="campaignTitle"
      type="text"
      ref="campaignTitle"
      @keydown="handleTitleInpuKeydown"
      @blur="saveTitle"
      class="campaign-title"
      :class="`bg-${bgVariant}`"
      placeholder="Campaign name"
    />
    <span class="ml-5" @click="editCampaignTitle">
      <svg-icon name="pencil" class="edit-title" />
    </span>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import NProgress from 'nprogress';
import { ALL_CAMPAIGNS_QUERY } from '~/graphql/queries';

export default {
  name: 'campaign-title',

  props: {
    bgVariant: {
      type: String,
      default: 'light',
    },
    campaignId: {
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    createCampaign: {
      type: Function,
      default: () => Promise.resolve(null),
    },
  },

  data: function() {
    return {
      campaignTitle: this.title,
    };
  },

  watch: {
    title: function(title) {
      this.campaignTitle = title;
    },
  },

  mounted() {
    this.adjustInput(this.$refs.campaignTitle);
  },

  updated() {
    this.adjustInput(this.$refs.campaignTitle);
  },

  methods: {
    handleTitleInpuKeydown(e) {
      this.adjustInput(e.target);
    },

    editCampaignTitle() {
      const titleElem = this.$refs.campaignTitle;
      titleElem.focus();
      titleElem.select();
    },

    adjustInput(elem) {
      const text = this.campaignTitle !== elem.value ? this.campaignTitle : elem.value;
      const length = text.length > 0 ? text.length : 'campaign name'.length;
      elem.style.width = (length + 1) * 13 + 'px';
    },

    async saveTitle() {
      const title = this.campaignTitle;

      // If title == this.title that means there where no changes
      if (title !== this.title && title !== '' && (parseInt(this.$route.params.id, 10) || this.campaignId)) {
        NProgress.start();

        await this.$apollo.mutate({
          mutation: gql`
            mutation($id: Int!, $title: String!) {
              campaign: saveCampaignTitle(id: $id, title: $title) {
                title
              }
            }
          `,
          variables: {
            id: parseInt(this.$route.params.id, 10) || this.campaignId,
            title,
          },
          refetchQueries: [{ query: ALL_CAMPAIGNS_QUERY }],
        });
        NProgress.done();
      }

      this.$emit('update', title);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

input {
  &:focus,
  &:active {
    outline: 0;
  }
}
.edit-title {
  cursor: pointer;
}
.title-wrap {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.campaign-title {
  font-size: 25px;
  border: 0;
  margin-top: 20px;
  margin-bottom: 17px;
  color: #222;
  max-width: 365px;
  padding: 0 6px;

  &::placeholder {
    color: #9aa2b4;
    font-size: 19px;
  }

  &:focus {
    border-bottom: 1px solid $primary;
  }
}
</style>
